<template>
  <div>
         <b-row class="justify-content-center" >
            <b-col cols="10" md="4" >
                <center>
                  <img src="elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : ''">
                     <br><br>
                    <br><br>
                   <video id="video" class="video-js vjs-default-skin" width="640px" height="267px" :style="isMobile ? 'max-width:1006px;width:90%;height:auto;' :'max-width:1006px;width:100%;height:auto;'"
                                        controls preload="auto" poster='video/portada2.jpg'
                                        data-setup='{ "aspectRatio":"748:549" }'>
                                        <source src="video/intro3.mp4" type="video/mp4" >
                    </video>
                </center>
            </b-col>
         </b-row>
  </div>
</template>

<script>
  // import Multiselect from 'vue-multiselect'
export default {
  name: 'Intro',
  //  components: { Multiselect },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
    }
  },
  methods:{
    
  },
  // mounted(){
  //   this.selectores();
  // }
}
</script>